import React, { Component } from 'react'
import SubpageLayout from './subpage-layout'
import { graphql } from 'gatsby'

// Static Query can be used anywhere, but it DOESNT accept variables and CANT use context

// Page Query must be used on pages, but can accept vars and context

export default class newsPageLayout extends Component {
   render() {
      const { markdownRemark } = this.props.data;
      return (
         <SubpageLayout>
            <h1>{markdownRemark.frontmatter.title}</h1>
            <p>{markdownRemark.frontmatter.date}</p>
            <div dangerouslySetInnerHTML={{
               __html:markdownRemark.html
            }} />
         </SubpageLayout>
      )
   }
}

export const query = graphql`
   query PostQuery($slug: String) {
      markdownRemark(frontmatter:{
      slug: {
         eq: $slug
      }
   }) {
      html
      frontmatter {
         title
         date
      }
      }
   }
`