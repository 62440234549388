import React from "react"

import twitter from '../images/icons/twitter.svg'
import instagram from '../images/icons/instagram.svg'
import richlandLibrary from '../images/logos/richland-library.svg'
import plus from '../images/icons/plus.svg'
import knightFoundation from '../images/logos/knight-foundation.svg'

import aten from '../images/logos/aten.svg'

const year = new Date().getFullYear();

const Footer = ({question, fact}) => (
  <>
  <footer className="site--footer">
    <div className="site--footer-top">
      <div className="site--footer-top-wrapper">
        <h3>{question || "Love what you see?"}</h3>
        <p>
        {fact || "Open source means it's free."}
        </p>

        <div className="form--wrapper">
          <form
            action="/thank-you/"
            name="updates"
            method="POST"
            netlify-honeypot="bot-field"
            netlify="true"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="updates" />
            <div className="form--input-wrapper">
              <input
              type="text"
              name="email"
              id="email"
              required
              />
              <label htmlFor="email">Enter Email for Updates</label>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>

        <p><a class="button" href="https://join.slack.com/t/interceptcommunity/shared_invite/zt-1qqr1run9-z1yUycBNMjo3HmEnFlzK6g">Join the discussion on Slack</a></p>

        <ul className="footer--social-icons">
          <li>
            <a href="https://twitter.com/libintercept"
              ><img src={twitter} alt="Twitter"
            /></a>
          </li>
          <li>
            <a href="https://www.instagram.com/libintercept"
              ><img src={instagram} alt="instagram"
            /></a>
          </li>
        </ul>
      </div>
    </div>

    <div className="site--footer-bottom">
      <h4>Made for libraries by libraries</h4>
      <p>A partnership between:</p>

      <ul className="footer--partnership-logos">
        <li>
          <a href="http://www.richlandlibrary.com/" target="_blank" rel="noopener noreferrer"
            ><img
              src={richlandLibrary}
              alt="Richland Library"
          /></a>
        </li>
        <li><img src={plus} alt="+" /></li>
        <li>
          <a href="https://knightfoundation.org/" target="_blank" rel="noopener noreferrer"
            ><img
              src={knightFoundation}
              alt="Knight Foundation"
          /></a>
        </li>
        <li><img src={plus} alt="+" /></li>
        <li>
          <a href="https://atendesigngroup.com/" target="_blank" rel="noopener noreferrer"
            ><img src={aten} alt="Aten"
          /></a>
        </li>
      </ul>

      <p className="copyright">
        &copy; {year} Richland Library &amp; Aten Design Group, Inc.
      </p>

      <p>
        <a href="/privacy-policy/" className="privacy-policy">Privacy Policy</a>
      </p>
    </div>
  </footer>
  </>
)

export default Footer